.user-info {
  .user-info-wrapper {
    width: 750px;
    margin: auto;
  }
  .cb-card {
    padding: 48px;
  }
}

.cta-card-wrapper {
  display: flex;
  justify-content: center;
  .cb-card {
    width: 50%;
  }
}

@media screen and (max-width: 1023px) {
  .cta-card-wrapper {
    display: flex;
    justify-content: center;
    .cb-card {
      width: 100%;
    }
  }
  .transcript-wrapper {
    width: 100%;
  }
}
