.header-wrapper {
  .table-header-content {
    display: flex;
    flex-wrap: wrap;
  }
  .content-wrapper {
    display: flex;
    flex-direction: row;
    flex: 0 0 50%;
  }
}

.category {
  text-transform: capitalize;
}

@media screen and (max-width: 1023px) {
  .header-wrapper {
    .table-header-content {
      .content-wrapper {
        flex: 100%;
        .key-label {
          width: 50%;
        }
        .value-label {
          width: 50%;
        }
      }
    }
  }
}
