.cb-file-upload {
  height: 170px;
  .cb-file-element-rm {
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.cb-spinner-div {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1023px) {
  .cta-card-wrapper {
    display: flex;
    justify-content: center;
    .cb-card {
      width: 100%;
    }
  }
  .transcript-wrapper {
    width: 100%;
  }
}
