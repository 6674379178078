.breadcrumbs {
  grid-area: breadcrumbs;
  .cb-breadcrumb {
    padding: 0px;
  }
}

#rootPath {
  pointer-events: none;
  text-decoration: none;
  color: #1e1e1e !important;
}
