.page-header {
  padding-left: "-72px";
  padding-right: "-72px";
  .page-header-content {
    width: 66%;
  }
  a:hover {
    text-decoration: underline;
    color: black;
  }
}

@media screen and (max-width: 1023px) {
  .page-header {
    display: flex;
    justify-content: center;
    .page-header-content {
      width: 100%;
    }
  }
}
